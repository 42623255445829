//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Navbar from "../components/Parts/Navbar";
import { mapState, mapActions } from "vuex";
import {EventBus} from "@/event-bus";

export default {
  name: "Default",
  components: { Navbar, DynamicForm: () => import('@/components/Forms/DynamicForm') },
  data: () => ({
    modalData: {},
    open: false
  }),
  computed: {
    ...mapState(["initialization", "alertMessage"])
  },
  methods: {
    ...mapActions(["resetAlertMessage"]),
    showAlert() {
      const closeTxt = 'x';
      let color = 'positive';
      let message = '';
      switch (this.alertMessage) {
        case "add":
          message = this.$t("common.messages.recordCreated");
          break;
        case "update":
          message = this.$t("common.messages.recordChanged");
          break;
        case "delete":
          color = 'warning';
          message = this.$t("common.messages.recordDeleted");
          break;
        case "error":
          color = 'negative';
          message = this.$t("common.messages.operationNotCompleted");
          break;
        case "not_valid":
          color = 'negative';
          message = this.$t("common.messages.operationNotValidated");
          break;
        case "over_size":
          color = 'negative';
          message = this.$t("common.messages.attachmentOverSize");
          break;
        case "none":
          this.resetAlertMessage();
          break;
        default:
          this.resetAlertMessage();
      }
      if (this.alertMessage != "none") {
        this.$q.notify({message, color, actions: [{ label: closeTxt, color: 'white', handler: () => {} }] });
      }
      this.resetAlertMessage();
    },
    resetModalForm() {
      this.open = false;
    }
  },
  watch: {
    alertMessage() {
      this.showAlert(this.alertMessage);
    },
  },
  mounted() {
    EventBus.$on('show-modal-form-default', (moduleName, action, ID) => {
      this.modalData.moduleName = moduleName;
      this.modalData.action = action;
      this.modalData.ID = ID;
      this.open = true;
    });
  }
};
