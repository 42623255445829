//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from "vuex";
import LocaleChanger from "./LocaleChanger";
import LoginEmail from "./../LoginEmail";

export default {
  name: "Navbar",
  components: { LocaleChanger, LoginEmail },
  props: {},
  data() {
    return {
      searchQuery: "",
      searchEnabled: false,
      dropdownEnabled: false,
      mobileData: false,
      tab: 0,
      showLoginDialog: false,
      showRegisterDialog: false,
    };
  },
  computed: {
    ...mapState(["initialization"]),
    ...mapState(["dynamicModules"]),
    ...mapGetters(["language"]),
    ...mapGetters("user", { profile: "profile", loggedIn: "loggedIn" }),
    dynamicMenus() {
      return Object.values(this.dynamicModules).sort((a, b) => {
      return a.order - b.order;
    });
    }
  },
  methods: {
    ...mapActions("user", { login: "login", logout: "logout" }),
    ...mapActions(["setLanguage"]),
  },
  mounted() {
    setTimeout(() => (this.showLoginDialog = !this.loggedIn), 1000);
  },
  updated() {
    this.showLoginDialog = !this.loggedIn;
  },
};
