



























import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class LocaleChanger extends Vue {
  @Prop(String) language;

  langs: Array<string> = ["en-GB", "nl-NL"];
  database = 'dev';

  get selectedLanguage() {
    return this.language;
  }

  set selectedLanguage(value: string) {
    this.$emit("setLanguage", value);
  }

  get onDatabase() {
    return this.database;
  }

  set onDatabase(value: string) {
    this.database = value;
    localStorage.setItem("databaseProd", value);
  }

  beforeMount() {
    this.database = localStorage.getItem("databaseProd") || "prod";
  }

}
