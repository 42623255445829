//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "./../firebase";

export default {
  name: "LoginEmail",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: null,
      database: 'dev',
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          this.$emit("cancelBtn");
        })
        .catch((err) => {
          this.error = err.message;
          alert(`
                      Login error:

                      ${err.message}
                      `);
        });
    },
  },

  computed: {
    onDatabase: {
      get() {
        return this.database;
      },
      set(value) {
        this.database = value;
        localStorage.setItem("databaseProd", value);
      },
    },
  },

  beforeMount() {
    this.database = localStorage.getItem("databaseProd") || "prod";
  },
};
